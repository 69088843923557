import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';

const Sidebar = ({ sections, handleScroll }) => {
  return (
    <List>
      {Object.keys(sections).map((sectionKey) => (
        <ListItem 
          button 
          key={sectionKey} 
          onClick={() => handleScroll(sectionKey)}
        >
          <ListItemText primary={sections[sectionKey]} />
        </ListItem>
      ))}
    </List>
  );
};

export default Sidebar;