import { Typography, Box } from '@mui/material';
import React from 'react';
import { BulletedList } from './BulletedList';
import { TypographyWithLink } from './TypographyWithLink'

const Content = ({ sections }) => {

  const getBulletPoints = (section) => {
    let bulletPointContent = []
    if (section.content.length > 1) {
      bulletPointContent = section.content
    } else if (section.content[0]?.bullet_points) {
      bulletPointContent = section.content[0]?.bullet_points
    }
    return bulletPointContent
  }

  return (
    <div>
      {sections.map((section, index) => (
        <div key={section.title} id={index} style={{ marginBottom: '20px' }}>
          <Typography variant="h4" component="h3" >
            {section.title}
          </Typography>
          {getBulletPoints(section).length > 0 ? (
            <BulletedList bulletPointcontent={getBulletPoints(section)} variant={'h5'} />
          ) : (
            <Box  sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              textAlign: 'justify',
              mt: 2,
              mb: 2,
              overflowX: 'auto',  // Enable horizontal scrolling
            }}>
              <TypographyWithLink text={section.content[0].text} />
              {section.content[0]?.map ?
                <Box className="div2" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt:7}}>
                  {/* Google Maps iframe */}
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d705.8159470143294!2d6.871424251776985!3d46.3178798819683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478e995c1d3f71ad%3A0xafc98b68ed2bbd72!2sD%C3%A9chetterie%20des%20Rotzas!5e0!3m2!1sfr!2sch!4v1725043359653!5m2!1sfr!2sch"
                    width="600"
                    height="450"
                    style={{ border: 1}}
                    allowfullscreen=""
                    loading="lazy">
                  </iframe>
                  {/* Hôtel de ville text */}
                  <Typography variant="h5" sx={{ mt: 1, ml: 2 }}>
                    Parking
                  </Typography>
                </Box> : null}
            </Box>
          )}
        </div>
      ))}
    </div>
  );
};

export default Content;