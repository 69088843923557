import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Typography, Box } from '@mui/material';

const BulletedList = ({ bulletPointcontent, variant }) => {
    const isNested = bulletPointcontent[0]?.bullet_points
    return (
        <List sx={{ listStyleType: 'disc' }}>
            {bulletPointcontent?.map((entry, index) => (
                <ListItem key={index} id={entry.title} sx={{ display: 'list-item' }}>
                    <Typography variant={variant}>
                        {isNested ? entry.subtitle : entry.title}
                    </Typography>
                    {isNested ? <BulletedList bulletPointcontent={entry.bullet_points} /> :
                        <Typography sx={{ textAlign: 'justify' }} variant='body1'>
                            {entry.content}
                        </Typography>
                    }
                </ListItem>
            ))}
        </List>
    )
}

export {
    BulletedList
}