import MenuIcon from '@mui/icons-material/Menu';
import {
	AppBar, Box, Container, CssBaseline, FormControl, Grid, IconButton, MenuItem, Select, Toolbar, Typography, Drawer, useMediaQuery, useTheme
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Content from "./components/Content";
import Sidebar from "./components/Sidebar";
import en from "./i18n/en.json";
import fr from "./i18n/fr.json";

function App() {
	const [language, setLanguage] = useState("fr");
	const [translations, setTranslations] = useState(fr);
	const [sidebarOpen, setSidebarOpen] = useState(true); // Manage sidebar state
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detect small screens

	const handleLanguageChange = (event) => {
		const lang = event.target.value;
		setLanguage(lang);
		setTranslations(lang === "en" ? en : fr);
	};

	const handleScroll = (sectionId) => {
		const section = document.getElementById(sectionId);
		if (section) {
			section.scrollIntoView({ behavior: "smooth" });
		}
	};

	const handleSidebarToggle = () => {
		setSidebarOpen(!sidebarOpen);
	};

	useEffect(() => {
		document.title = "Daffy's Wedding"
	}, []);
	  

	const LanguageSelector = () => {
		return (
			<FormControl sx={{ m: 1, minWidth: 80 }}>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={language}
					label="Language"
					defaultValue={"fr"}
					onChange={handleLanguageChange}
					sx={{
						color: 'white', // Set text color to white
						backgroundColor: 'transparent', // Make background transparent
						'& .MuiSelect-icon': {
							color: 'white', // Set the icon color to white
						},
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: 'transparent', // Set border color to white
						},
						'&:hover': {
							'& .MuiOutlinedInput-notchedOutline': {
								borderColor: 'white', // Ensure border color is white on hover
							},
						},
					}}
				>
					<MenuItem value={"fr"}>FR</MenuItem>
					<MenuItem value={"en"}>EN</MenuItem>
				</Select>
			</FormControl>
		)
	}

	const sectionTitles = translations.sections.map(s => s?.title)
	return (
		<Box sx={{ display: "flex", alignItems: 'center' }}>
			<CssBaseline />
			<AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
				<Toolbar>
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						sx={{ mr: 2 }}
						onClick={handleSidebarToggle} // Handle menu icon click
					>
						<MenuIcon />
					</IconButton>
					<Box sx={{ flexGrow: 1 }} />
					<Typography variant="h6" component="div">
						Daffy's Wedding
					</Typography>
					<Box sx={{ flexGrow: 1 }} />
					<LanguageSelector />
				</Toolbar>
			</AppBar>
			<Drawer
				variant={isSmallScreen ? "temporary" : "permanent"}
				open={sidebarOpen}
				onClose={handleSidebarToggle}
				sx={{
					width: 'flex',
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: { boxSizing: "border-box" },
				}}
			>
				<Toolbar />
				<Box sx={{ overflow: "auto" }}>
					<Sidebar
						sections={sectionTitles}
						handleScroll={handleScroll}
					/>
				</Box>
			</Drawer>
			<Box
				component="main"
				sx={{ flexGrow: 1, p: 3, mt: '64px' }}>
				<Container>
					<Content sections={translations.sections} />
				</Container>
			</Box>
		</Box >
	);
}

export default App;
