import React from 'react';
import { Typography, Link } from '@mui/material';

const TypographyWithLink = ({text}) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return (
        <Typography variant="body1" sx={{ textAlign: 'justify'}}>
            {parts.map((part, index) => (
                urlRegex.test(part) ? (
                    <Link key={index} href={part} target="_blank" rel="noopener">
                        {part}
                    </Link>
                ) : (
                    part
                )
            ))}
        </Typography>
    );
};

export {
    TypographyWithLink}
